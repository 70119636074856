<template>
    <div>
        <div class="map_content">
            <div id="container"></div>
            <div id="panel"></div>
        </div>
        <div style="margin: 0 10px 10px;">
            <el-button class="cartoon_btn" id="start" @click="startAnimation()">开始轨迹动画</el-button>
            <el-button class="cartoon_btn" id="stop" @click="stopAnimation()">停止轨迹动画</el-button>
        </div>
    </div>
</template>

<script>
import icon from './car.png'
export default {
    name: 'ElDialogMapTrack',
    props: {
        options: {
            type: Object,
            default: []
        }
    },
    data() {
        return {
            map: null,
            row: {},
            lineArr: '',
            place_address: '',
            target_address: '',
            newLineArr: [],
        }
    },
    mounted() {
        // console.log('options>>>',this.options);
        //     this.row = this.options.row;
        //     this.lineArr = this.options.lineArr;
        // this.initMap()
    },
    watch: {
        options: {
            handler(newVal) {
                this.row = newVal.row;
                this.lineArr = newVal.lineArr;
                this.place_address = newVal.row.place_address;
                this.target_address = newVal.row.target_address;
                this.$nextTick(() => {
											this.initMap()
                })
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        // getNewArray(array, subGroupLength) {
        //   let index = 0;
        //   let newArray = [];
        //   while(index < array.length) {
        //       newArray.push(array.slice(index, index += subGroupLength));
        //   }
        //   return newArray;
        // },
        initMap() {
            var that = this;
            that.map = new AMap.Map('container', {
                resizeEnable: true, // 窗口大小调整
                center: this.lineArr[0], // 中心 firstArr: [116.478935, 39.997761],
                zoom: 19
            })
            var markerContent = '' +
                '<div class="custom-content-marker" style="width:100%;color:red">' +
                '   <img src="https://test.oss.livingtrip.uyl.cn/1665454065152mmexport1665454015343.png">' +
                '   <div style="width:300px;color:red;margin-left: -300px;">'
                + '起点位置 : ' + this.place_address +
                '</div>'
            '</div>';
            var markerContents = '' +
                '<div class="custom-content-marker" style="width:100%;color:red">' +
                '   <img src="https://test.oss.livingtrip.uyl.cn/1665454069448mmexport1665454011210.png">' +
                '   <div style="width:300px;color:red;margin-top:-100px;position: fixed">'
                + '终点位置 : ' + this.target_address +
                '</div>'
            '</div>';
            var marker = new AMap.Marker({
                position: this.lineArr[0],
                // 将 html 传给 content
                content: markerContent,
                // 以 icon 的 [center bottom] 为原点
                offset: new AMap.Pixel(-13, -30)
            });
            var count = this.lineArr.length - 1
            var markers = new AMap.Marker({
                position: this.lineArr[count],
                // 将 html 传给 content
                content: markerContents,
                // 以 icon 的 [center bottom] 为原点
                offset: new AMap.Pixel(-13, -30)
            });
            // 将 markers 添加到地图
            this.map.add(marker);
            this.map.add(markers);
            var startIcon = new AMap.Icon({
                // 图标尺寸
                size: new AMap.Size(100, 100),
                // 图标的取图地址
                image: icon,
                // 图标所用图片大小
                imageSize: new AMap.Size(35, 56),
                // // 图标取图偏移量
                imageOffset: new AMap.Pixel(11, -3)
            });
            // 添加maker
            this.marker = new AMap.Marker({
                map: this.map,
                position: this.lineArr[0],
                // content: markerCar,
                icon: startIcon,
                offset: new AMap.Pixel(-20, -13), // 调整图片偏移
                autoRotation: true, // 自动旋转
                angle: -90 // 图片旋转角度
            })
            this.map.add(this.marker);
            // //console.log(this.marker)
            // 创建一个 Icon
            that.initroad()
        },
        foobar(points){
            // console.log('points@@@@',points);
            var that = this;
            var grasp = new AMap.GraspRoad();
            let items = points.shift();
            if(!items) return
            // console.log('items@@@@',items);

            grasp.driving(items,(error, result) => {
                // console.log('result', result)
                if (!error) {
                    var path2 = [];
                    var newPath = result.data.points;
                    newPath.forEach(item => {
                        let newArr = [item.x, item.y]
                        that.newLineArr.push(newArr)

                    })
                    for (var i = 0; i < newPath.length; i += 1) {
                        path2.push([newPath[i].x, newPath[i].y])
                    }
                    that.polyline = new AMap.Polyline({
                        map: that.map,
                        path: path2,
                        strokeWeight: 8,
                        strokeOpacity: 0.8,
                        strokeColor: '#0091ea',
                        showDir: true
                    })
                    that.map.setFitView()
                    that.foobar(points)
                }
            })
        },
        // 初始化轨迹
        initroad() {
            var that = this;
            var arr = [];
            var news = [];
            if (that.lineArr != '') {
                that.lineArr.forEach(function (item, index) {
                    //  //console.log(item+'时间戳')
                    item.x = item[0]
                    item.y = item[1]
                    item.tm = index
                    item.sp = item[2]
                    item.ag = item[3]
                    // item.tm = item[2]
                    arr.push(
                        {
                            "x": parseFloat(item.x),
                            "y": parseFloat(item.y),
                            "sp": 0,//parseFloat(item.sp),
                            "ag": 0,//parseFloat(item.ag),
                            "tm": item.tm
                        }
                    )
                })
                that.newLineArr = []
                var grasp = new AMap.GraspRoad();
                let i = Math.ceil(arr.length / 500)
                // console.log('arr', arr)
                let newArr = this.$util.getNewArray(arr, 500)
                // newArr.reverse()
                // console.log('newArr@', newArr.length,newArr[5])
                if (i > 1) {                    
                    this.foobar(newArr)
                } else {
                    grasp.driving(arr, (error, result) => {
                        console.log('errorr', error)
                        if (!error) {
                            var path2 = [];
                            var newPath = result.data.points;
                            newPath.forEach(item => {
                                let newArr = [item.x, item.y]
                                that.newLineArr.push(newArr)
                            })
                            for (var i = 0; i < newPath.length; i += 1) {
                                path2.push([newPath[i].x, newPath[i].y])
                            }
														// console.log('path2', path2)
                            that.polyline = new AMap.Polyline({
                                map: that.map,
                                path: path2,
                                strokeWeight: 8,
                                strokeOpacity: 0.8,
                                strokeColor: '#0091ea',
                                showDir: true
                            })
                            that.map.setFitView()
                        }else {
													that.polyline = new AMap.Polyline({
													    map: that.map,
													    path: that.lineArr,
													    strokeWeight: 8,
													    strokeOpacity: 0.8,
													    strokeColor: '#0091ea',
													    showDir: true
													})
													that.map.setFitView()
												}
                    })
                }
                // grasp.driving(arr, (error, result) => {
                //     console.log('errorr', error)
                //     if (!error) {
                //         var path2 = [];
                //         var newPath = result.data.points;
                //         that.newLineArr = []
                //         newPath.forEach(item => {
                //             let newArr = [item.x, item.y]
                //             that.newLineArr.push(newArr)
                //         })
                //         for (var i = 0; i < newPath.length; i += 1) {
                //             path2.push([newPath[i].x, newPath[i].y])
                //         }
                //         that.polyline = new AMap.Polyline({
                //             map: that.map,
                //             path: path2,
                //             strokeWeight: 8,
                //             strokeOpacity: 0.8,
                //             strokeColor: '#0091ea',
                //             showDir: true
                //         })
                //         // var passedPolyline = new AMap.Polyline({
                //         //     map: that.map,
                //         //     strokeColor: "#AF5",  //线颜色
                //         //     strokeWeight: 8,      //线宽
                //         // });
                //         // that.marker.on('moving', function (e) {
                //         //     passedPolyline.setPath(e.passedPath);
                //         //     that.map.setCenter(e.target.getPosition(), true)
                //         // });
                //         // that.map.add(newLine)
                //         that.map.setFitView()
                //     }
                // })
            }
        },
        startAnimation(arr) {
            // console.log('that.newLineArr', this.newLineArr)

            this.marker.moveAlong(this.newLineArr, {
                // 每一段的时长
                duration: 500,//可根据实际采集时间间隔设置
                // JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
                autoRotation: true,
            });
        },
        stopAnimation() {
            this.marker.stopMove();
        }
    }
}
</script>

<style scoped>
.map_content {
    width: 100%;
    height: 550px;
    padding: 0 10px 20px;
    box-sizing: border-box;
}

#container {
    width: 100%;
    height: 100%;
}

#panel {
    position: fixed;
    background-color: white;
    max-height: 90%;
    overflow-y: auto;
    top: 10px;
    right: 10px;
    width: 280px;
}

.cartoon_btn {
    color: #39ABF4;
    border: 1px solid #39ABF4;
}
</style>