<template>
	<div class="ele-body">
		<!-- 新需求 -->
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="订单状态:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="1" />
						<el-option label="待接单" value="2" />
						<el-option label="进行中" value="3" />
						<el-option label="已完成" value="4" />
						<el-option label="已失效" value="5" />
						<el-option label="乘客取消" value="6" />
						<el-option label="司机取消" value="7" />
						<el-option label="手动结束" value="8" />
						<el-option label="违约金" value="9" />
						<el-option label="空驶补偿" value="10" />
						<el-option label="待支付" value="11" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="1000" />
						<el-option label="电召叫单（实时单）" value="2000" />
						<el-option label="人工（实时单）" value="3000" />
						<el-option label="人工（指派）" value="4000" />
						<el-option label="人工（预约单）" value="5000" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单标记:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-select v-model="table.where.sign" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="" />
						<el-option label="爱心订单" value="1000" />
						<el-option label="学校订单" value="2000" />
						<el-option label="普通订单" value="3000" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable />
				</el-form-item>
				<el-form-item label="用户叫单号码:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.user_phone" placeholder="请输入用户叫单号码" clearable />
				</el-form-item>
				<el-form-item label="司机姓名:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_name" placeholder="请输入司机姓名" clearable />
				</el-form-item>
				<el-form-item label="司机号码:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_phone" placeholder="请输入司机号码" clearable />
				</el-form-item>
				<el-form-item label="车牌号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_carnumber" placeholder="请输入车牌号" clearable />
				</el-form-item>
				<el-form-item label="省:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-150">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' style="width: 170px ;" @change="$forceUpdate()"
						clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单时间：" style="display: flex ; align-items: center; padding-left: 10px; margin-right: 10px;"
					clearable>
					<el-date-picker v-model="value" @change="birthdayChange" type="datetimerange"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
						end-placeholder="结束日期" :default-time="['00:00:00']">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<audio src="https://kyd.oss.livingtrip.uyl.cn/2b986e92c6224afcebf96484b7debab2.mp4" ref="MusicPlay"
						hidden></audio>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="reset_search()">重置</el-button>

					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="order/exportExcelLivery"
						:params="exportOrderParams()" :outtime='6000' v-if="permission.includes('sys: callList:export')"
						ref="childDownload" />
					<el-button type="primary" @click="mohuClick()" icon="el-icon-bell" class="ele-btn-icon"
						style="margin-left:90px">模糊订单铃音示警</el-button>
					<el-button type="primary" @click="yichangClick()" icon="el-icon-bell" class="ele-btn-icon"
						style="margin-left: 10px">异常订单铃音示警</el-button>
					<el-button @click="addDept" class="addbtn ">人工下单</el-button>
				</el-form-item>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :cell-class-name="nopaddingCell" :choose.sync="choose"
				height="calc(100vh - 315px)" highlight-current-row :stripe="true">
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="180" />
					<el-table-column prop="order_address" label="所属地区" show-overflow-tooltip min-width="180" />
					<el-table-column prop="type_name" label="订单类型" show-overflow-tooltip min-width="140" />
					<el-table-column prop="user_phone" label="下单号码" show-overflow-tooltip min-width="120" />
					<el-table-column label="下单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column>
					<!-- <el-table-column label="计划出发时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column> -->
					<el-table-column prop="place_address_title" label="出发地" show-overflow-tooltip min-width="200" />
					<el-table-column prop="target_address_title" label="目的地" show-overflow-tooltip min-width="200" />
					<el-table-column prop="audio" label="推送语音" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">
							<el-link @click="bofang(row)" v-if="row.jiequ_audio" icon="el-icon-video-play" type="primary"
								:underline="false">播放录音</el-link>
						</template>
					</el-table-column>
					<el-table-column label="接单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.receive_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="driver_name" label="接单司机" show-overflow-tooltip min-width="100" />
					<el-table-column prop="driver_phone" label="司机号码" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="grab_position_title" label="接单位置" show-overflow-tooltip min-width="200" />
					<el-table-column label="行程开始时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.strat_car_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="行程结束时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.end_car_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="订单取消时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.cancel_time * 1000 | toDateString}}</template>
					</el-table-column>
					<el-table-column label="订单状态" show-overflow-tooltip min-width="140">
						<template slot-scope="{row}">
							<div class="status">{{ row.status_name }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="push_type" label="订单标记" show-overflow-tooltip min-width="120">
						<template
							slot-scope="{row}">{{ row.push_type == 0 ? '普通订单' : (row.push_type == 1 ? '爱心订单' : (row.push_type == 2 ? '学校订单' : ''))}}</template>
					</el-table-column>
					<el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="120" />

					<el-table-column prop="contact_passengers_price" label="空驶补偿(元)" show-overflow-tooltip min-width="120">
						<template
							slot-scope="{row}">{{ row.contact_passengers_price == null ? '/' : row.contact_passengers_price }}</template>
					</el-table-column>
					<el-table-column prop="cancel_money" label="违约金（元）" show-overflow-tooltip min-width="120" />
					<el-table-column prop="star" label="乘客评分" show-overflow-tooltip min-width="120" />
					<el-table-column label="操作" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">
							<el-link
								v-if="((row.status !== 2 && row.status !== 10) || row.has_cancel_order_info) && permission.includes('sys: callList:order_finish')"
								@click="overOrder(row)" icon="el-icon-s-claim" type="primary" :underline="false">手动结束</el-link>
						</template>
					</el-table-column>
					<el-table-column prop="admin_name" label="手动结束账号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="finish" label="结束原因" show-overflow-tooltip min-width="120" />
					<el-table-column prop="payment_text" label="支付方式" show-overflow-tooltip min-width="120" />
					<el-table-column label="更多" width="270px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link v-if="permission.includes('sys:callList:detail')" @click="handlecheck(row)" icon="el-icon-view"
								type="primary" :underline="false">完整定位
							</el-link>
							<el-link
								v-if="permission.includes('sys:callList:viewtrack') && ((row.status_name.indexOf('已完成') != -1 && row.driver_id) || row.status_name.indexOf('进行中') != -1 || row.status_name.indexOf('空驶补偿') != -1)"
								@click="viewTrack(row)" icon="el-icon-location-information" type="primary" :underline="false">查看轨迹
							</el-link>
							<el-link v-if="permission.includes('sys:callList:travelprogress') && row.url" @click="tripSchedule(row)"
								icon="el-icon-paperclip" type="primary" :underline="false">行程进度
							</el-link>
						</template>

					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 行程进度弹窗 -->
		<el-dialog v-dialogDrag title="行程进度" :visible.sync="tripShow" width="408px" :destroy-on-close="true"
			:lock-scroll="false">
			<el-card shadow="never">
				<iframe style="width: 375px; height: 648px;" :src="tripUrl"></iframe>
			</el-card>
		</el-dialog>
		<!-- 手动结束弹窗 -->
		<el-dialog v-dialogDrag title="确定手动结束" :visible.sync="overOrderShow" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="overOrderList" ref="overOrderList" @submit.native.prevent :rules="editRules"
				style="margin: 10px 0;">
				<el-form-item label="手动结束原因:" label-width="120px">
					<el-input v-model="finish" placeholder="手动结束原因" style="width: 450px;" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="overOrderShow=false">取消</el-button>
				<el-button type="primary" @click="order_status_update(overOrderList)">确认</el-button>
			</div>
		</el-dialog>



		<!-- 对话框：添加 -->
		<el-dialog v-dialogDrag title="95128电召人工下单" :visible.sync="dialogFormVisibleAddReturnActivity" width="1300px"
			@closed="() => {
				zdOrederShow = false
				editForm = {
				}
				carDet = {}
				cityArr = []
				districtArr = []
			}" :destroy-on-close="true" :lock-scroll="false">
			<div>
				<div id="orderMap" class="order-map">
				</div>
				<div class="map-form">
					<el-card shadow="never">
						<el-form :model="editForm" ref="editForm" label-width="120px" :rules="editRules">
							<el-form-item label="订单所属地：" prop="pid">
								<el-select v-model="editForm.pid" @change="handleChangeProv(editForm.pid)" class="city-select" clearable
									placeholder="请选择省">
									<el-option v-for="option in provArr" :value="option.pid" :key="option.pid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editForm.cid" @change="handleChangeCity(editForm.cid)" class="city-select" clearable
									placeholder="请选择市">
									<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid"
										:label="option.name"></el-option>
								</el-select>
								<el-select v-model="editForm.aid" @change="handleChangeArea(editForm.aid)" class="city-select" clearable
									placeholder="请选择区/县">
									<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
										:label="option.name"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="乘客叫单号码：" prop="phone">
								<el-input v-model="editForm.phone" :value="phone" style="width: 200px;" placeholder="请输入号码或座机号"
									clearable />
							</el-form-item>
							<div style="display: flex;">
								<div class="place_point"></div>
								<el-form-item label="出发地：" style="margin-left: -60px;">
									<!-- <el-select style="width: 300px;" v-model="editForm.name2" filterable remote reserve-keyword
					          placeholder="请输入关键词" :remote-method="remoteMethod2" :loading="sloading2" @change="schange2">
					          <el-option v-for="item in sOptions2" :key="item.value" :label="item.label" :value="item.value">
					          </el-option>
					        </el-select> -->
									<el-autocomplete popper-class="my-autocomplete" v-model="editForm.name2" :debounce="300"
										:fetch-suggestions="querySearchAsync2" :trigger-on-focus="false" placeholder="输入关键词"
										class="order-input" @select="schange2">
										<template slot-scope="{ item }">
											<div class="name" v-html="searchTitleHtml(item.label)"></div>
											<div class="addr">
												{{item.district?item.district + item.address:item.pname +item.cityname + item.adname + item.address}}
											</div>
										</template>
									</el-autocomplete>
									<i v-show="!iconLoading" class="el-icon-refresh-right refresh-cars"
										style="margin-left: 10px; font-size: 20px;" @click="refreshCars"></i>
									<i v-show="iconLoading" class="el-icon-loading refresh-cars"
										style="margin-left: 10px; font-size: 20px;"></i>
									<!-- <span>刷新附近车辆</span> -->
								</el-form-item>
							</div>

							<div style="display: flex;margin-top: 20px;">
								<div class="target_point"></div>
								<el-form-item label="目的地：" style="margin-left: -60px;">
									<!-- <el-select style="width: 300px;" v-model="editForm.name" filterable remote reserve-keyword
					          placeholder="请输入关键词" :remote-method="remoteMethod" :loading="sloading" @change="schange">
					          <el-option v-for="item in sOptions" :key="item.value" :label="item.label" :value="item.value">
					          </el-option>
					        </el-select> -->
									<el-autocomplete popper-class="my-autocomplete" v-model="editForm.name" :debounce="300"
										:fetch-suggestions="querySearchAsync" :trigger-on-focus="false" placeholder="输入关键词"
										class="order-input" @select="schange">
										<template slot-scope="{ item }">
											<div class="name" v-html="searchTitleHtml(item.label)"></div>
											<div class="addr">
												{{item.district?item.district + item.address:item.pname +item.cityname + item.adname + item.address}}
											</div>
										</template>
									</el-autocomplete>
								</el-form-item>
							</div>
						</el-form>
						<div style="margin-top: 20px; text-align: center;">
							<el-button type="primary" @click="save">确认下单</el-button>
						</div>
					</el-card>
				</div>
				<div class="zd-order" v-if="zdOrederShow">
					<el-card shadow="never">
						<div class="zd-order-header">
							<span class="header-tit">车辆状态</span>
							<span class="header-close" @click="zdClose">X</span>
						</div>
						<div class="zd-order-cont">
							<div>
								<span>车牌号:</span>
								<span>{{carDet.car_number}}</span>
							</div>
							<div>
								<span>司机姓名:</span>
								<span>{{carDet.name}}</span>
							</div>
							<div>
								<span>司机账号:</span>
								<span>{{carDet.driver_phone}}</span>
							</div>
							<div>
								<span>目前状态:</span>
								<span>{{carDet.is_carry==0 && carDet.is_accept_user_specified_order == 0?'空车'
								:carDet.is_carry==0 && carDet.is_accept_user_specified_order != 0?'点我'
								:'载客'}}</span>
								<span v-if="carDet.is_accept_user_specified_order == 0">(不接收指派订单)</span>
								<span v-if="carDet.is_accept_user_specified_order != 0 && carDet.is_carry==0">
									{{"(接收指派订单,距离出发地约" + carDet.distance +"公里)"}}
								</span>
							</div>
						</div>
						<el-button class="zp-btn"
							:type="carDet.is_carry==1 || carDet.is_accept_user_specified_order==0?'info':'primary'"
							:disabled="carDet.is_carry==1 || carDet.is_accept_user_specified_order==0?true:false"
							@click="saveZdOreder">指派下单</el-button>
					</el-card>
				</div>
			</div>
		</el-dialog>
		<!-- 完整定位 -->
		<el-dialog v-dialogDrag title="完整定位" :visible.sync="dialogTableVisibleCheck" @close='closeShow'
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never" v-if="isReloadData">
				<el-form :model="checkForm" label-width="140px">
					<div style="display: flex;">
						<div style="width:100%;box-sizing: border-box;padding: 0 50px 0 10px;">
							<el-form-item label="出发地：">
								{{ checkForm.place_address_title ? '【' + checkForm.place_address_title + '】' + checkForm.place_address : checkForm.place_address }}
							</el-form-item>
							<el-form-item label="目的地：">
								{{ checkForm.target_address_title ? '【' + checkForm.target_address_title + '】' + checkForm.target_address : checkForm.real_offcar_address }}
							</el-form-item>
							<el-form-item label="接单位置：">
								{{ checkForm.grab_driver_address }}
							</el-form-item>
							<el-form-item label="结束位置：">
								{{ checkForm.real_offcar_address }}
							</el-form-item>
						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 查看轨迹 -->
		<el-dialog v-dialogDrag title="查看车辆行驶轨迹" :visible.sync="trackShow" @closed="editForm = {}" :destroy-on-close="true"
			:lock-scroll="false">
			<ElDialogMapTrack :options="options"></ElDialogMapTrack>
		</el-dialog>
	</div>
</template>
<!--<link rel="stylesheet" href="https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css" />-->

<!-- <script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
<script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script> -->
<script>
	import ElDialogMapTrack from '@/components/ElDialogMapTrack'
	import download from '@/components/ListDownload/index'
	// import MiddleUtil2 from '@/config/middles2.js';
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",
		components: {
			download,
			ElDialogMapTrack
		},
		data() {
			return {
				trackShow: false, //轨迹弹窗控制
				options: {
					row: {},
					lineArr: ''
				},
				phone: "",
				isReloadData: true,
				pickerOptionsStart: {
					disabledDate: time => {
						let endDateVal = this.tableend_time;
						if (endDateVal) {
							return time.getTime() > new Date(endDateVal).getTime();
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: time => {
						let beginDateVal = this.tableCreate_time;
						if (beginDateVal) {
							return (
								time.getTime() <
								new Date(beginDateVal).getTime()
							);
						}
					}
				},
				isPlay: true,
				tableCreate_time: '', //搜索选中时间戳
				tableend_time: '',
				bgWidth: '',
				aa: true,
				ampDisplay: false,
				dialogVisibleAdd: false,
				gStar: [0, 0, 0, 0, 0],
				gStartext: [false, false, false, false, false],
				charList: [],
				table: {
					url: '/order/indexOrderLivery',
					where: {
						source: 2
					}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {
				}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则
					phone: [{
						required: true,
						message: '请输入号码',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的号码',
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					name2: [{
						required: true,
						message: '请输入起始地',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请输入性别，1男2女',
						trigger: 'blur'
					}],
					home: [{
						required: true,
						message: '请输入家乡名称，对应aid',
						trigger: 'blur'
					}],

					money: [{
						required: true,
						message: '请输入余额',
						trigger: 'blur'
					}],

					losemoney: [{
						required: true,
						message: '请输入已提现/使用余额',
						trigger: 'blur'
					}],


				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				time: [],
				cityArr: [],
				districtArr: [],
				audioArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				zoom: 12,
				center: [104.07, 30.67],
				ampSrc: '',
				driverInfo: {},
				orderInfo: {},
				audio: '',
				autoPlays: false,
				dialogFormVisibleAddReturnActivity: false,
				sOptions: [],
				timer: null,
				sloading: false,
				map: null,
				sOptions2: [],
				timer2: null,
				sloading2: false,
				map2: null,
				orderMap: null,
				marker: null,
				marker2: null,
				carDet: {},
				zdOrederShow: false,
				searchValue: "",
				markerCars: [],
				adcodes: '',
				adminCid: '',
				tripShow: false, //行程弹窗
				tripUrl: '', //行程链接
				player: '',
				value: '',
				overOrderList: {},
				overOrderShow: false, //控制手动结束填写原因
				finish: '',
				iconLoading: false,
				// setAddress:null,
				infoWindowObj:'',//点击窗体按钮缓存的数据
				provNamePams:'',//存省市区名称,地理编码查询用
				cityNamePams:'',
				adNamePams:''
			}
		},
		created() {
			window.setAddress = (e) => {
				if(e == '1') {//设为出发地
					this.$set(this.editForm, "name2", '')
					this.schange2(this.infoWindowObj)
				}else {
					this.$set(this.editForm, "name", '')
					this.schange(this.infoWindowObj)
				}
			}
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})

			var url = this.$route.query.orderId
			if (url != undefined && url != '') {
				this.dialogTableVisibleChatRecord = true
				this.getChat(url)
			} else {
				this.dialogTableVisibleChatRecord = false
			}
		},
		beforeRouteLeave(to, form, next) {
			next()
			//console.log('离开了页面')
			// this.player.pause();
			this.player = ''
		},
		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {
			this.getAdcodes()
			// this.$nextTick(() => {
			// 	this.addDept()
			// })
		},
		methods: {
			//手动结束
			overOrder(row) {
				this.overOrderList = row;
				this.overOrderShow = true;
			},
			//播放语音
			bofang(row) {
				console.log('row', row);
				if (this.player) {
					this.player.pause();
				}
				this.player = new Audio(row.jiequ_audio);
				// this.player.currentTime = 1;
				this.player.play();
			},
			initOrderMap() {
				let that = this
				//console.log('触发了')
				var map = new AMap.Map("orderMap", {
					resizeEnable: true,
					zoom: 14,
					isHotspot: true
				}); //初始化地图
				that.orderMap = map
				AMap.plugin([
					'AMap.ToolBar',
				], function() {
					// 在图面添加工具条控件, 工具条控件只有缩放功能
					that.orderMap.addControl(new AMap.ToolBar());
				});
				//信息窗口实例
				var infoWindow = new AMap.InfoWindow({
					offset: new AMap.Pixel(-3, 0),
					// anchor:'bottom-center'
				});
				let timer = null
				that.orderMap.on('click', e=> {
					setTimeout(() => {
						let nowTimer = new Date().getTime()
						if((nowTimer - timer) <= 300) { //当点击地图展示出来的地点时,阻止地图的点击事件
							return
						}
						fetch(
								`https://restapi.amap.com/v3/geocode/regeo
								?location=${e.lnglat.lng + ',' + e.lnglat.lat}
								&key=776a143516222c0a4ede18ae5dce8587
								&extensions=all`
								, {
									method: 'get'
								}).then(response => response.json())
							.then(res => {
								console.log('resres', res)
								if (res.infocode == "10000") {
									let {
										province,
										city,
										district,
										township,
										streetNumber,
										adcode
									} = res.regeocode.addressComponent
									//省市区街道拼接
									let baseAddress = province + city + district + township
									//删除省市区街道字符,获取地点名称
									let useAddress = res.regeocode.formatted_address.split(baseAddress)[1]
									//获取道路名称,用于拼接##后面的
									let street = streetNumber.street + streetNumber.number
									let obj = {
										paramsStr:useAddress?useAddress + '##' + street:res.regeocode.formatted_address,
										location:e.lnglat.lng + ',' + e.lnglat.lat,
										name:useAddress?useAddress:res.regeocode.formatted_address,
										adcode: adcode
									}
									that.infoWindowObj = obj
									let content = `<div class="InfoWindow-content2">
																		<div class="InfoWindow-title">${useAddress?useAddress:res.regeocode.formatted_address}</div>
																		<div class="InfoWindow-btn">
																			<div class="InfoWindow-btn-cont">
																				<span class="yuan1"></span>
																				<span class="btn" onclick="setAddress('1');" style="border: 1px solid red;" >设为出发地</span>
																			</div>
																			<div class="InfoWindow-btn-cont">
																				<span class="yuan2"></span>
																				<span class="btn" onclick="setAddress('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																			</div>												
																		</div>
																		<div class="info-shu">
																		</div>
																</div>`
									infoWindow.setContent(content)
									infoWindow.open(that.orderMap, e.lnglat);
								}
							})
					},10)
					
				})
				//点击地图上展示出来的地点
				that.orderMap.on('hotspotclick', (e) => {
					console.log('eee',e)
					timer = new Date().getTime() //获取时间戳
					if(e.id) {//如果有地点标识
						fetch(
								`https://restapi.amap.com/v3/place/detail
								?id=${e.id}
								&key=776a143516222c0a4ede18ae5dce8587`
								, {
									method: 'get'
								}).then(response => response.json())
							.then(res => {
								console.log('resresres', res)
								if (res.infocode == "10000") {
									let obj
									if(res.pois[0]) {//如果能查到具体位置
										obj = {
											paramsStr:res.pois[0].name + '##' + res.pois[0].address,
											location:res.pois[0].location,
											name:res.pois[0].name,
											adcode: res.pois[0].adcode
										}
										that.infoWindowObj = obj
										let content = `<div class="InfoWindow-content2">
																			<div class="InfoWindow-title">${res.pois[0].name}</div>
																			<div class="InfoWindow-btn">
																				<div class="InfoWindow-btn-cont">
																					<span class="yuan1"></span>
																					<span class="btn" onclick="setAddress('1');" style="border: 1px solid red;" >设为出发地</span>
																				</div>
																				<div class="InfoWindow-btn-cont">
																					<span class="yuan2"></span>
																					<span class="btn" onclick="setAddress('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																				</div>												
																			</div>
																			<div class="info-shu">
																			</div>
																	</div>`
										infoWindow.setContent(content)
										infoWindow.open(that.orderMap, e.lnglat);
									}else {//查不到具体位置时,用坐标去查									
										fetch(
												`https://restapi.amap.com/v3/geocode/regeo
												?location=${e.lnglat.lng + ',' + e.lnglat.lat}
												&key=776a143516222c0a4ede18ae5dce8587
												&extensions=all`
												, {
													method: 'get'
												}).then(response => response.json())
											.then(res => {
												console.log('resres', res)
												if (res.infocode == "10000") {
													let {
														province,
														city,
														district,
														township,
														streetNumber,
														adcode
													} = res.regeocode.addressComponent
													//省市区街道拼接
													let baseAddress = province + city + district + township
													//删除省市区街道字符,获取地点名称
													let useAddress = res.regeocode.formatted_address.split(baseAddress)[1]
													//获取道路名称,用于拼接##后面的
													let street = streetNumber.street + streetNumber.number
													// 窗体标题
													/**
													 * 此具体地址是截取后不为空的地址 useAddress
													 * 
													 * 
													 * 有具体地址?具体地址包含点击的地点名并且具体地址不包含'号楼、栋、幢'几个字:展示点击的地点名
													 * 否则具体地址包含'号楼、栋、幢'几个字:展示具体地点名:否则展示具体地点名+点击的地点名:
													 * 否则截取完的具体地址为空.直接展示未截取的地址
													 */
													let title = useAddress?useAddress.indexOf(e.name) != -1 && useAddress.indexOf('号楼') == -1 && useAddress.indexOf('幢') == -1 && useAddress.indexOf('栋') == -1?
																						e.name:useAddress.indexOf('号楼') != -1 || useAddress.indexOf('幢') != -1 || useAddress.indexOf('栋') != -1?useAddress:
																						useAddress+e.name:res.regeocode.formatted_address
													let obj = {
														paramsStr:title + '##' + street,
														location:e.lnglat.lng + ',' + e.lnglat.lat,
														name:title,
														adcode: adcode
													}
													that.infoWindowObj = obj
													let content = `<div class="InfoWindow-content2">
																						<div class="InfoWindow-title">${title}</div>
																						<div class="InfoWindow-btn">
																							<div class="InfoWindow-btn-cont">
																								<span class="yuan1"></span>
																								<span class="btn" onclick="setAddress('1');" style="border: 1px solid red;" >设为出发地</span>
																							</div>
																							<div class="InfoWindow-btn-cont">
																								<span class="yuan2"></span>
																								<span class="btn" onclick="setAddress('2')" style="border: 1px solid #33CC99;">设为目的地</span>
																							</div>												
																						</div>
																						<div class="info-shu">
																						</div>
																				</div>`
													infoWindow.setContent(content)
													infoWindow.open(that.orderMap, e.lnglat);
												}
											})
									}
									
								}
							})
					}else {
						that.$message('抱歉!未能获取该地点信息')
					}
					
					
				})
				
			},
			// 人工下单点击
			addDept() {
				//console.log('i>>>', 77777777777777777777);

				this.dialogFormVisibleAddReturnActivity = true
				let that = this;
				this.$nextTick(() => {
					that.initOrderMap()
				})
			},
			nopaddingCell(val) {
				if (!val.column.label) {
					return "";
				}
				if (val.column.label.indexOf("订单状态") == -1) {
					return ""
				}

				if (!val.row.status_name) {
					return "";
				}
				let e = val.row.status_name;

				if (!e) {
					return "nopaddingCell"
				} else if (e.indexOf('已完成') != -1) {
					return 'cellDone'
				} else if (e.indexOf('进行中') != -1) {
					return 'cellProcess'
				} else if (e.indexOf('取消') != -1 || e.indexOf('失效') != -1) {
					return 'cellCancel'
				} else if (e.indexOf('待接单') != -1) {
					return 'cellWait'
				}

				return "nopaddingCell";
			},
			schange(item) {
				console.log('item', item)
				let that = this
				if (that.marker) {
					that.orderMap.remove(that.marker)
				}
				//筛选出选中的对象
				let latlngArr = item.location.split(',')
				this.editForm.name = item.name
				this.editForm.paramsStr = item.paramsStr
				this.editForm.lng = latlngArr[0]
				this.editForm.lat = latlngArr[1]
				// that.orderMap.clearMap()
				let icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(25, 34),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(135, 40),
					// 图标取图偏移量
					imageOffset: new AMap.Pixel(-95, -3)
				});
				this.marker = new AMap.Marker({
					position: latlngArr,
					icon: icon,
				});
				that.orderMap.add(this.marker);
				console.log('this.marker', this.marker)
				that.orderMap.setFitView();
			},
			querySearchAsync(query, cb) {
				this.searchValue = query;
				console.log('query', query)
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid

				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?parameters&keywords=${query}&city=${cid ? cid : ''}&citylimit=${cid?'false':'true'}&offset=10&page=1&key=776a143516222c0a4ede18ae5dce8587`, {

								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions = res.pois
								this.sOptions.forEach(item => {
									item.paramsStr = item.name + '##' + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions;
								if (restaurants.length == 0) {
									this.querySearchAsync1(query, cb);
								} else {
									cb(restaurants);							
								}
								// cb(restaurants);
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			querySearchAsync1(query, cb) {//高德输入提示查询API
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/assistant/inputtips?keywords=${query}
							&city=${cid ? cid : ''}&citylimit=${cid?'false':'true'}
							&datatype=all&key=776a143516222c0a4ede18ae5dce8587`, {
								method: 'get'
							}).then(response => response.json())
							.then(res => {
								console.log('querySearchAsync1res', res)
								if (res.infocode == 10000) {
									this.sOptions = res.tips
									this.sOptions.forEach(item => {
										item.paramsStr = item.name + '##' + item.address
										item.value = item.name
										item.label = item.value
									})
									var restaurants = this.sOptions;
									// if (restaurants.length == 0) {
										
									// } else {
									// 	cb(restaurants);
									
									// }
									cb(restaurants);
								} else {
									this.$message({
										message: res.info
									});
								}
							})
				}
			},
			refreshCars() {
				if (this.editForm.paramsStr2 && this.editForm.name2) {
					this.zdOrederShow = false
					this.iconLoading = true
					let obj = {
						lng: this.editForm.lng2,
						lat: this.editForm.lat2,
					}
					this.getCars(obj)
				} else {
					this.$message('请先选择出发地')
				}

			},
			//获取附近车辆
			getCars(obj) {
				let that = this
				if (this.markerCars.length) {
					this.markerCars.forEach(item => {
						this.orderMap.remove(item)
					})
				}
				this.$http.post('/order/getNearbyDrivers', {
					lng: obj.lng,
					lat: obj.lat,
					max_distance: 2,
				}).then(res => {
					console.log('getCars', res)
					var marker, icon
					if (res.data.code == 100) {
						if (res.data.data.length) {
							res.data.data.forEach(item => {
								item.latlngArr = [item.lng, item.lat]
								item.distance = this.getMapDistance(obj.lng, obj.lat, item.lng, item.lat)
								icon = new AMap.Icon({
									// size: new AMap.Size(50, 70),
									image: item.car_image,
									imageSize: new AMap.Size(50, 70),
									// imageOffset: new AMap.Pixel(0, 0)
								});
								marker = new AMap.Marker({
									position: item.latlngArr, //不同标记点的经纬度
									map: that.orderMap,
									icon: icon,
								});
								that.markerCars.push(marker)
								marker.on('click', (e) => {
									return this.markerClick(item)
								});
							})
						} else {
							that.$message({
								message:'附近暂无车辆',
								customClass:'cars-message',
							})
						}
						that.iconLoading = false
					} else {
						that.iconLoading = false
						that.$message({
							type: 'error',
							message: res.data.msg
						})
					}
				}).catch(err => {
					that.iconLoading = false
					that.$message({
						type: 'error',
						message: err.message
					})
				})
			},
			markerClick(item) {
				console.log('item', item)
				this.carDet = item
				this.zdOrederShow = true
			},
			zdClose() {
				this.carDet = {}
				this.zdOrederShow = false
			},
			getMapDistance(lng1, lat1, lng2, lat2) {
				var d1 = 0.01745329251994329;
				var d2 = lng1;
				var d3 = lat1;
				var d4 = lng2;
				var d5 = lat2;
				d2 *= d1;
				d3 *= d1;
				d4 *= d1;
				d5 *= d1;
				var d6 = Math.sin(d2);
				var d7 = Math.sin(d3);
				var d8 = Math.cos(d2);
				var d9 = Math.cos(d3);
				var d10 = Math.sin(d4);
				var d11 = Math.sin(d5);
				var d12 = Math.cos(d4);
				var d13 = Math.cos(d5);
				var arrayOfDouble1 = [];
				var arrayOfDouble2 = [];
				arrayOfDouble1.push(d9 * d8);
				arrayOfDouble1.push(d9 * d6);
				arrayOfDouble1.push(d7);
				arrayOfDouble2.push(d13 * d12);
				arrayOfDouble2.push(d13 * d10);
				arrayOfDouble2.push(d11);
				var d14 = Math.sqrt((arrayOfDouble1[0] - arrayOfDouble2[0]) * (arrayOfDouble1[0] - arrayOfDouble2[0]) +
					(arrayOfDouble1[1] - arrayOfDouble2[1]) * (arrayOfDouble1[1] - arrayOfDouble2[1]) +
					(arrayOfDouble1[2] - arrayOfDouble2[2]) * (arrayOfDouble1[2] - arrayOfDouble2[2]));
				var distance = (Math.asin(d14 / 2.0) * 12742001.579854401) / 1000;
				return distance.toFixed(1);
			},
			//获取adcode权限
			getAdcodes() {
				this.$http.post('/order/getAdminCity')
					.then(res => {
						console.log(res)
						this.adcodes = res.data.data.area_id
						let oCid = res.data.data.city_id;
						let oPid = res.data.data.province_id;
						if (oCid) {
							if (oCid.indexOf(",") == -1) {
								this.adminCid = oCid;
							} else {
								if (oPid) {
									if (oPid.indexOf(",") == -1) { // 单个省
										this.adminCid = oPid
									} else { // 多个省
										console.log("省列表", oPid);
									}
								}
							}
						}
					})
			},
			schange2(item) {
				console.log('item2', item)
				if (this.adcodes && this.adcodes.indexOf(item.adcode) == -1) {
					this.editForm.name2 = ''
					this.$message({
						message: '请选择订单所属地区的出发地'
					})
					return
				}
				let that = this
				if (that.marker2) {
					that.orderMap.remove(that.marker2)
				}
				//筛选出选中的对象
				let latlngArr = item.location.split(',')
				this.editForm.name2 = item.name
				this.editForm.paramsStr2 = item.paramsStr
				this.editForm.lng2 = latlngArr[0]
				this.editForm.lat2 = latlngArr[1]
				// that.orderMap.clearMap()
				let icon = new AMap.Icon({
					// 图标尺寸
					size: new AMap.Size(30, 40),
					image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
					imageSize: new AMap.Size(140, 45),
					// 图标取图偏移量
					imageOffset: new AMap.Pixel(-9, -3)
				});
				this.marker2 = new AMap.Marker({
					position: latlngArr,
					icon: icon,
				});
				that.orderMap.add(this.marker2);
				//用来获取附近车辆
				let obj = {
					lng: latlngArr[0],
					lat: latlngArr[1],
				}
				// this.$forceUpdate()
				that.getCars(obj)
				that.orderMap.setFitView();
			},
			querySearchAsync2(query, cb) {
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}
							&extensions=all&city=${aid ? aid : cid ? cid : ''}
							&citylimit=${cid ? 'true' : 'false'}
							&offset=20&page=1&key=776a143516222c0a4ede18ae5dce8587`, {
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions2 = res.pois
								this.sOptions2.forEach(item => {
									item.paramsStr = item.name + '##' + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions2;
								// cb(restaurants);
								if (restaurants.length == 0) {
									this.querySearchAsync3(query, cb);
								} else {
									cb(restaurants);

								}
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			querySearchAsync3(query, cb) {
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/place/text?keywords=${query}
							&extensions=all&city=${aid ? aid : cid ? cid : ''}
							&citylimit=true
							&offset=20&page=1&key=776a143516222c0a4ede18ae5dce8587`, {
								method: 'get'
							}).then(response => response.json())
						.then(res => {
							console.log('res', res)
							if (res.infocode == 10000) {
								this.sOptions2 = res.pois
								this.sOptions2.forEach(item => {
									item.paramsStr = item.name + '##' + item.address
									item.value = item.name
									item.label = item.value
								})
								var restaurants = this.sOptions2;
								if (restaurants.length == 0) {
									this.querySearchAsync4(query, cb);
								} else {
									cb(restaurants);							
								}
								// cb(restaurants);
							} else {
								this.$message({
									message: res.info
								});
							}
						})
				}
			},
			querySearchAsync4(query, cb) {//高德输入提示查询API
				this.searchValue = query;
				let cid = this.editForm.cid ? this.editForm.cid : this.adminCid
				let aid = this.editForm.aid
				if (query) {
					fetch(
							`https://restapi.amap.com/v3/assistant/inputtips?keywords=${query}
							&city=${aid ? aid : cid ? cid : ''}&citylimit=${cid ? 'true' : 'false'}
							&datatype=all&key=776a143516222c0a4ede18ae5dce8587`, {
								method: 'get'
							}).then(response => response.json())
							.then(res => {
								if (res.infocode == 10000) {
									this.sOptions2 = res.tips
									this.sOptions2.forEach(item => {
										item.paramsStr = item.name + '##' + item.address
										item.value = item.name
										item.label = item.value
									})
									var restaurants = this.sOptions2;
									// if (restaurants.length == 0) {
									// } else {
									// 	cb(restaurants);
									
									// }
									cb(restaurants);
								} else {
									this.$message({
										message: res.info
									});
								}
							})
				}
			},
			mohuClick() {
				this.$refs.MusicPlay.src = this.$root.lingTiShiAudio;
				//console.log('22222mplay', this.$refs.MusicPlay.src);
				this.$refs.MusicPlay.play();


			},
			yichangClick() {
				this.$refs.MusicPlay.src = this.$root.InterveneAudio;
				//console.log('111111mplay', this.$refs.MusicPlay.src);
				this.$refs.MusicPlay.play();

			},
			ReloadData() {
				this.isReloadData = false
				this.$nextTick(() => {
					this.isReloadData = true
				})
			},
			birthdayChange() {
				console.log('value', this.value);
				if (this.value != null) {
					this.$set(this.table.where, 'create_time', this.value)
					// this.table.where.start_day = this.tableTime[0]
					// this.table.where.end_day = this.tableTime[1]
				} else {
					this.table.where.create_time = []
				}
				//var time = [];
				//				if(this.table.where.create_time.length >0){
				// this.time[0] = this.tableCreate_time
				// this.table.where.create_time = this.time;
				//				}else{
				//					(this.table.where.create_time).unshift(this.tableCreate_time)
				//				}
				//console.log(this.tableCreate_time)
				//console.log(this.table.where.create_time)
			},
			birthdayChange1() {
				//if(this.table.where.create_time.length >0){
				this.time[1] = this.tableend_time
				this.table.where.create_time = this.time;
				//console.log(this.table.where.create_time)

				//				}else{
				//					(this.table.where.create_time).push(this.tableend_time)
				//				}
				//				(this.table.where.create_time).push(this.tableend_time)
			},
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				const params = this.params

				// //console.log('-----11---params------------',params)
				// //console.log('-----22---query------------',query)
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			//重置
			reset_search() {

				this.value = '';
				this.$refs.childDownload.clearWhere();
				this.table.where = {
					source: 2
				}
				this.tableCreate_time = '';
				this.tableend_time = '';
				this.$refs.table.reload()
			},
			hanlePicture(src) {
				this.ampDisplay = true
				this.ampSrc = src
			},
			handleClose() {
				this.ampDisplay = false
				this.autoPlays = false
			},
			closeShow() {

				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].currentTime = 12
					middlePlay[i].pause()
				}
				this.autoPlays = false

			},
			closeDialog() {
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].currentTime = 12
					middlePlay[i].pause()
				}
			},
			handlePlay(url, id, index) {
				let that = this
				var middlePlay = document.querySelectorAll('.pasuVideo')
				middlePlay[0].currentTime = 12
				middlePlay[0].pause()
				var middle = 'middleindex'
				middlePlay = this.$refs[middle]
				middlePlay.play()
				that.isPlay = false
				middlePlay.addEventListener('ended', function() {
					that.isPlay = true
				})


			},
			dropClick(command, row) {
				if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'chat') { //紧急联系人
					this.handleChat(row)
				} else if (command === 'getChat') { //聊天记录
					this.getChat(row)
				} else if (command === 'track') { //聊天记录
					this.viewTrack(row)
				} else if (command === 'trip') {
					this.tripUrl = row.url
					console.log('row.url', row.url);
					this.$nextTick(() => {
						this.tripShow = true
					})
				}
			},
			//查看轨迹
			viewTrack(row) {
				console.log('row', row);
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/getOneTrajectory', {
					order_id: row.order_id
				}).then(res => {
					loading.close();
					this.options.row = row;
					this.options.lineArr = res.data.data.driver_place;
					this.trackShow = true;
				})
			},
			tripSchedule(row) {
				this.tripUrl = row.url
				console.log('row.url', row.url);
				this.$nextTick(() => {
					this.tripShow = true
				})
			},
			saveZdOreder() {
				this.$refs['editForm'].validate((valid) => {
					//console.log(this.editForm)

					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						//如果没有选择出发地或者选择出发地后又删除了
						if (!this.editForm.paramsStr2 || !this.editForm.name2) {
							this.$message({
								type: 'error',
								message: '出发地不能为空',
							});
							this.$refs.table.reload();
							// this.dialogFormVisibleAddReturnActivity = false
							loading.close();
							return
						}



						// 重新拼接详细地址,如果地址不包含建筑物名称则拼接name
						let tempArr = this.editForm.paramsStr2.split('##');
						if (tempArr.length == 2) {
							let name = this.editForm.name2
							if (tempArr[1].indexOf(tempArr[0]) == -1) {
								let newAddress = name + '##' + tempArr[1] + tempArr[0];
								this.editForm.name2 = newAddress;
							} else {
								this.editForm.name2 = this.editForm.paramsStr2;
							}
						}
						//只输入目的地不选择或者选择了目的地然后删除了,清空name
						if (this.editForm.paramsStr && this.editForm.name) {
							let tempArr = this.editForm.paramsStr.split('##');
							let name = this.editForm.name
							if (tempArr.length == 2) {
								if (tempArr[1].indexOf(tempArr[0]) == -1) {
									let newAddress = name + '##' + tempArr[1] + tempArr[0];
									this.editForm.name = newAddress;
								} else {
									this.editForm.name = this.editForm.paramsStr;
								}
							}
						} else {
							this.editForm.name = ''
							this.editForm.paramsStr = ''
						}
						let zdFrom = this.editForm
						zdFrom.driver_id = this.carDet.id
						this.$http.post('/order/insertOrderUser', zdFrom).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								this.$refs.table.reload();
								this.dialogFormVisibleAddReturnActivity = false
								this.zdOrederShow = false
								this.carDet = {}
							} else {
								this.$message.error({
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			save() {

				this.$refs['editForm'].validate((valid) => {
					//console.log(this.editForm)

					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						//如果没有选择出发地或者选择出发地后又删除了
						if (!this.editForm.paramsStr2 || !this.editForm.name2) {
							this.$message({
								type: 'error',
								message: '请选择订单所属地区的出发地',
							});
							this.$refs.table.reload();
							// this.dialogFormVisibleAddReturnActivity = false
							loading.close();
							return
						}



						// 重新拼接详细地址,如果地址不包含建筑物名称则拼接name
						let tempArr = this.editForm.paramsStr2.split('##');
						if (tempArr.length == 2) {
							let name = this.editForm.name2
							if (tempArr[1].indexOf(tempArr[0]) == -1) {
								let newAddress = name + '##' + tempArr[1] + tempArr[0];
								this.editForm.name2 = newAddress;
							} else {
								this.editForm.name2 = this.editForm.paramsStr2;
							}

						}

						//只输入目的地不选择或者选择了目的地然后删除了,清空name
						if (this.editForm.paramsStr && this.editForm.name) {
							let tempArr = this.editForm.paramsStr.split('##');
							let name = this.editForm.name
							if (tempArr.length == 2) {
								if (tempArr[1].indexOf(tempArr[0]) == -1) {
									let newAddress = name + '##' + tempArr[1] + tempArr[0];
									this.editForm.name = newAddress;
								} else {
									this.editForm.name = this.editForm.paramsStr;
								}
							}
						} else {
							this.editForm.name = ''
							this.editForm.paramsStr = ''
						}
						this.$http.post('/order/dzManualOrder', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
								this.$refs.table.reload();
								this.dialogFormVisibleAddReturnActivity = false
								this.zdOrederShow = false
								this.carDet = {}
								this.cityArr = []
								this.districtArr = []
							} else {
								this.$message.error({
									message: res.data.msg,
									// duration:0,
									// showClose:true
								});
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			searchTitleHtml(val) {
				if (this.searchValue.length == 0) {
					return val;
				}

				if (val.indexOf(this.searchValue) != -1) {
					let span = "<span style=\"color:red;\">" + this.searchValue + "</span>"
					return val.replace(this.searchValue, span);
				}
				return val;

			},
			// 聊天记录
			getChat(id) {
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".records")
				});
				this.$http.post('/order/chat_log', {
					id: id
				}).then(res => {
					var data = res.data
					loading.close()
					data.log.forEach(function(item) {
						item.isTimeShow = 1
						if (item.content.S == 21) {
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)

						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					this.charList = data.log
					this.driverInfo = data.driver
					this.orderInfo = data.order_info
				})

			},
			handleChat(row) {
				this.dialogTableVisibleChatRecord = true
				this.getChat(row.id)
			},
			/***选择省**/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach((item) => {
					if (item.pid == e) {
						pid = item.pid
						this.provNamePams = item.name
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					// //console.log(this.cityArr)
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/***选择市**/
			handleChangeCity(e) {
				if (e) {
					/** 获取被选市的cid**/
					var cid = ''
					this.cityArr.forEach((item) => {
						if (item.cid == e) {
							cid = item.cid
							this.cityNamePams = item.name
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						var data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.table.where.aid = ''
					})
				} else {
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			// 选择区/县
			handleChangeArea(e) {
				if (e) {
					/** 获取被选市的cid**/
					var aid = ''
					this.districtArr.forEach((item) => {
						if (item.aid == e) {
							aid = item.aid
							this.adNamePams = item.name
						}
					})
				}
			},
			/***查看用户信息**/
			handlecheck(row) {
				console.log('row>>', row);
				this.dialogTableVisibleCheck = true
				this.checkForm = row;
				this.autoPlays = true
				this.isPlay = false
				this.ReloadData()

			},
			endaudio(row) {
				var that = this
				that.isPlay = true
			},
			loadedmetadata() {
				//console.log('loadedmetadata');
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					//console.log(middlePlay[i]);
					middlePlay[i].currentTime = 12;
					middlePlay[i].pause()
				}
			},
			canplay() {

			},

			/*** 刪除(批量刪除)*/
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的订单吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/order/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/order/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},
			/*** 手动结束*/
			order_status_update(row) {
				// this.$confirm('确定完成此订单?', '提示', {
				// 	type: 'warning'
				// }).then(() => {
				// 	const loading = this.$loading({
				// 		lock: true
				// 	});
				if (this.finish) {
					this.$http.post('/order/order_status_update', {
						id: row.id,
						order_id: row.order_id,
						finish: this.finish,
					}).then(res => {
						this.overOrderShow = false;
						// loading.close();
						this.finish = ''
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						this.finish = ''
						this.overOrderShow = false;
						// loading.close();
						this.$message.error(e.message);
					});
				} else {
					this.$message('请填写手动结束原因');
				}
				// }).catch(() => 0);
			},
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('order data export');
				this.$http
					.get("/order/exportExcelLivery", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
	.bgGreen {
		background: #89B929 !important;
		color: #fff;
	}

	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-form-item-label {
		width: 20%;
		text-align: left
	}

	.disCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mr-10 {
		margin-right: 10px;
	}

	.el-date-editor /deep/.el-input,
	.el-date-editor /deep/.el-input__inner {
		width: 100% !important;
	}

	/deep/.el-dialog {
		margin-top: 0px !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 1400px;
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #fff;
		height: 160px;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.smsInner {
		border-bottom: 1px solid #ddd;
		padding-bottom: 14px;
	}

	.itemBox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
	}

	.place_point {
		width: 10px;
		height: 10px;
		margin: 13px 10px 0 40px;
		border-radius: 50%;
		background-color: #F8140E;
	}

	.target_point {
		width: 10px;
		height: 10px;
		margin: 13px 10px 0 40px;
		border-radius: 50%;
		background-color: #35CC99;
	}

	.order-map {
		width: 100%;
		height: 800px;
		position: relative;
	}

	.map-form {
		position: absolute;
		top: 60px;
		left: 10px;
		z-index: 100;
	}

	.zd-order {
		position: absolute;
		left: 10px;
		top: 370px;
		z-index: 100;
		width: 540px;
	}

	.zd-order-header {
		display: flex;
		justify-content: space-between;
	}

	.header-tit {
		color: #409EFF;
		font-weight: bold;
	}

	.header-close:hover {
		cursor: pointer;
	}

	.zd-order-cont {
		margin-top: 10px;
		position: relative;
	}

	.zp-btn {
		position: absolute;
		width: 150px;
		right: 50px;
		top: 60px;
	}

	.zd-order-cont div {
		margin-bottom: 5px;
	}

	.zd-order-cont div span {
		margin-right: 10px;
	}

	.city-select {
		width: 120px;
		margin-right: 10px;
	}

	::v-deep .el-dialog__body {
		padding: 0 0 0 0 !important;
		max-height: 800px !important;
	}

	.order-input {
		width: 350px;
	}

	.my-autocomplete li {
		line-height: normal;
		padding: 7px 7px 15px 7px;
	}

	.my-autocomplete li .name {
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: normal;
	}

	.my-autocomplete li .addr {
		font-size: 12px;
		color: #b4b4b4;
		line-height: normal;
		margin-top: 3px;
		margin-bottom: 8px;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.my-autocomplete li .highlighted .addr {
		color: #ddd;
		margin-top: 3px;
		margin-bottom: 8px;
		line-height: normal;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.refresh-cars:hover {
		cursor: pointer;
	}

	/deep/.el-table .cell {
		display: flex;
	}
	
</style>